import {
  bearing,
  degreesToRadians,
  destination,
  distance,
  lineString,
  lineIntersect,
  polygon as createPolygon,
} from '@turf/turf';

import CoordUtils from './CoordUtils';
import { getDistanceAlongPath } from './MapUtils';

export const getPositions = (boundary, options) => {
  const cursorLine = getFirstCursorLine(boundary, options); // [start, end]
  const [startPosVariance, endPosVariance] = getVariances(options);

  let lines = [];
  // eslint-disable-next-line no-constant-condition
  while (true) {
    cursorLine[0].lng += meterToLng(startPosVariance.x, cursorLine[0].lat);
    cursorLine[0].lat += meterToLat(startPosVariance.y);
    cursorLine[1].lng += meterToLng(endPosVariance.x, cursorLine[1].lat);
    cursorLine[1].lat += meterToLat(endPosVariance.y);

    // 교차점 획득
    const intersections = getIntersections(boundary, cursorLine);
    // 교차점 없는 경우
    if (intersections.length === 0) {
      if (lines.length === 0) continue;
      else break;
    }

    // 역방향인 경우
    if (lines.length % 2) {
      intersections.reverse();
    }

    const positions = [];
    // 카메라 사용 시
    if (options.camera) {
      intersections.forEach((intersection, index) => {
        positions.push({
          ...intersection,
          shoot: index % 2 === 0,
        });
      });
    }
    // 카메라 미사용 시
    else {
      positions.push(intersections[0]);
      positions.push(intersections[intersections.length - 1]);
    }

    lines.push(positions);
  }

  // Redefine with turnaround
  if (options.turnaround.before > 0 || options.turnaround.after > 0) {
    lines = getTurnaroundLines(options, lines);
  }

  // Split lines into segments
  if (options.segment) {
    lines = getSegmentedLines(options, lines);
  }

  // Return all points of lines
  return lines.flat(1);
};

const getMovedCoordinate = (origin, distance, direction) => {
  const movedPoint = destination(origin, distance, direction);
  return CoordUtils.objectFromArray(movedPoint.geometry.coordinates);
};

const getTurnaroundLines = ({ turnaround, camera }, lines) => {
  return lines.map((positions) => {
    let [start, end] = [positions[0], positions[positions.length - 1]];
    const from = CoordUtils.arrayFromObject(start);
    const to = CoordUtils.arrayFromObject(end);

    if (turnaround.before > 0) {
      start = getMovedCoordinate(from, turnaround.before / 1000, bearing(to, from));
      positions = [start, ...positions];
    }
    if (turnaround.after > 0) {
      end = getMovedCoordinate(to, turnaround.after / 1000, bearing(from, to));
      positions = [...positions, end];
    }

    return camera ? positions : [start, end];
  });
};

const getFirstCursorLine = (boundary, options) => {
  // 모든 위도, 경도 값
  const lats = boundary.map(({ lat }) => lat);
  const lngs = boundary.map(({ lng }) => lng);
  // 위도 최소, 최대값 획득
  const minLat = Math.min(...lats);
  const maxLat = Math.max(...lats);
  // 경도 최소, 최대값 획득
  const minLng = Math.min(...lngs);
  const maxLng = Math.max(...lngs);

  // 수평선
  if (options.rotate === 0) {
    return [
      { lat: minLat, lng: minLng },
      { lat: minLat, lng: maxLng },
    ];
  }
  // 내각
  else if (options.rotate < 90) {
    return [
      { lat: minLat, lng: minLng },
      { lat: minLat, lng: minLng },
    ];
  }
  // 수직선
  else if (options.rotate === 90) {
    return [
      { lat: minLat, lng: minLng },
      { lat: maxLat, lng: minLng },
    ];
  }
  // 외각
  else {
    return [
      { lat: minLat, lng: maxLng },
      { lat: minLat, lng: maxLng },
    ];
  }
};

const getVariances = (options) => {
  // 수평선
  if (options.rotate === 0) {
    return [
      { x: 0, y: options.gap },
      { x: 0, y: options.gap },
    ];
  }
  // 내각
  else if (options.rotate < 90) {
    return [
      { x: Math.pow(Math.sin(degreesToRadians(options.rotate)), -1) * options.gap, y: 0 },
      { x: 0, y: Math.pow(Math.cos(degreesToRadians(options.rotate)), -1) * options.gap },
    ];
  }
  // 수직선
  else if (options.rotate === 90) {
    return [
      { x: options.gap, y: 0 },
      { x: options.gap, y: 0 },
    ];
  }
  // 외각
  else {
    return [
      { x: 0, y: Math.pow(Math.sin(degreesToRadians(options.rotate - 90)), -1) * options.gap },
      { x: -Math.pow(Math.cos(degreesToRadians(options.rotate - 90)), -1) * options.gap, y: 0 },
    ];
  }
};

const getIntersections = (boundary, line) => {
  const from = CoordUtils.arrayFromObject(line[0]);
  const to = CoordUtils.arrayFromObject(line[1]);

  // 교차점 탐색
  line = lineString([from, to]);
  const polygon = createPolygon([boundary.map((coordinates) => CoordUtils.arrayFromObject(coordinates))]);
  const intersections = lineIntersect(line, polygon).features.map(({ geometry }) => geometry.coordinates);

  // 교차점 거리순 정렬
  intersections.sort((a, b) => distance(from, a) - distance(from, b));

  return intersections.map((intersection) => CoordUtils.objectFromArray(intersection));
};

const getSegmentedLines = (options, lines) => {
  const segments = [];

  let currentSegment = [];
  lines.forEach((line) => {
    const distance = getDistanceAlongPath([...currentSegment, ...line]);

    // 분할 거리 초과인 경우
    if (options.segment.distance < distance) {
      segments.push(currentSegment);
      currentSegment = [];
    }
    currentSegment.push(...line);
  });

  // 마지막 세그먼트 추가
  segments.push(currentSegment);

  // 각 경로점 세그먼트 순번 추가
  return segments.map((segment, index) =>
    segment.map((position) => ({
      ...position,
      segment: index,
    }))
  );
};

const meterToLat = (meter) => {
  return meter / 111320;
};

const meterToLng = (meter, latitude) => {
  return meter / ((40075000 * Math.cos(degreesToRadians(latitude))) / 360);
};
